@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
}

body {
  background: white;
}

.amount-positive {
  color: rgb(137, 199, 184);
}
.amount-negative {
  color: rgb(226, 130, 127);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  text-align: left;
}

a {
  color: #8144ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

a:hover {
  color: #a36eff;
}

a:active {
  color: #6230d9;
}

/* List of all breakpoints */
/* MainTable 1280px */
/* Header 680px */
/* LoginPage 576px */
